import { useState, useMemo, useCallback } from 'react'
import { Currency } from '@pancakeswap/sdk'
import { useTranslation } from '@pancakeswap/localization'
import { Flex, Button, HelpIcon, useTooltip, Text, Link, useToast } from '@pancakeswap/uikit'
import { fetchRiskToken, RiskTokenInfo } from 'views/Swap/hooks/fetchTokenRisk'
import RiskMessage from 'views/Swap/components/AccessRisk/RiskMessage'
import { tokenListFromOfficialsUrlsAtom } from 'state/lists/hooks'
import merge from 'lodash/merge'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { useAtomValue } from 'jotai'

interface AccessRiskProps {
  inputCurrency: Currency
  outputCurrency: Currency
}

const AccessRisk: React.FC<AccessRiskProps> = ({ inputCurrency, outputCurrency }) => {
  const { t } = useTranslation()
  const { toastInfo } = useToast()
  const tokenMap = useAtomValue(tokenListFromOfficialsUrlsAtom)

  const { address: inputAddress, chainId: inputChainId } = useMemo(() => (inputCurrency as any) ?? {}, [inputCurrency])
  const { address: outputAddress, chainId: outputChainId } = useMemo(
    () => (outputCurrency as any) ?? {},
    [outputCurrency],
  )

  const [{ results, loading }, setState] = useState<{
    results: { [chainId: number]: { [address: string]: RiskTokenInfo } }
    loading: boolean
  }>({
    results: {},
    loading: false,
  })
  const tokensForScan = useMemo(() => {
    const tokensToScan = []
    if (
      inputCurrency &&
      !inputCurrency.isNative &&
      !results[inputChainId]?.[inputAddress] &&
      !tokenMap?.[inputChainId]?.[inputAddress]
    ) {
      tokensToScan.push(inputCurrency)
    }
    if (outputCurrency && !outputCurrency.isNative && !results[outputChainId]?.[outputAddress]) {
      tokensToScan.push(outputCurrency)
    }
    return tokensToScan
  }, [results, inputAddress, inputChainId, outputAddress, outputChainId, inputCurrency, outputCurrency, tokenMap])

  const handleScan = useCallback(() => {
    const fetchTokenRisks = async () => {
      const tokenRiskResults = await Promise.all(
        tokensForScan.map((tokenToScan) => {
          const { address, chainId } = tokenToScan as any
          return fetchRiskToken(address, chainId)
        }),
      )

      setState((prevState) => ({
        ...prevState,
        loading: false,
        results: merge(
          { ...prevState.results },
          mapValues(groupBy(tokenRiskResults, 'chainId'), (tokenRiskResult) => keyBy(tokenRiskResult, 'address')),
        ),
      }))
    }

    toastInfo(
      t('Scanning Risk'),
      t('Please wait until we scan the risk for %symbol% token', {
        symbol: tokensForScan.map((currency) => currency.symbol).join(','),
      }),
    )
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }))
    fetchTokenRisks()
  }, [tokensForScan, toastInfo, t])

  const disabledButton = useMemo(() => loading || tokensForScan.length === 0, [loading, tokensForScan])

  // Tooltips
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<></>, { placement: 'bottom' })

  return <></>
}

export default AccessRisk
