import {
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  useMemo,
} from "react";
import styled from "styled-components";
import { Currency, CurrencyAmount, Percent } from "@pancakeswap/sdk";
import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  IconButton,
  ArrowUpDownIcon,
  Skeleton,
} from "@pancakeswap/uikit";
import { useIsTransactionUnsupported } from "hooks/Trades";
import UnsupportedCurrencyFooter from "components/UnsupportedCurrencyFooter";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useTranslation } from "@pancakeswap/localization";
import { maxAmountSpend } from "utils/maxAmountSpend";
import { useSwapActionHandlers } from "state/swap/useSwapActionHandlers";
import AccessRisk from "views/Swap/components/AccessRisk";

import CurrencyInputPanel from "components/CurrencyInputPanel";
import { CommonBasesType } from "components/SearchModal/types";
import { AutoRow, RowBetween } from "components/Layout/Row";
import { AutoColumn } from "components/Layout/Column";

import { useCurrency } from "hooks/Tokens";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "hooks/useApproveCallback";
import useWrapCallback, { WrapType } from "hooks/useWrapCallback";

import { Field } from "state/swap/actions";
import { useDerivedSwapInfo, useSwapState } from "state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
} from "state/user/hooks";

import replaceBrowserHistory from "@pancakeswap/utils/replaceBrowserHistory";
import { currencyId } from "utils/currencyId";

import CurrencyInputHeader from "./CurrencyInputHeader";
import SwapCommitButton from "./SwapCommitButton";
import useWarningImport from "../hooks/useWarningImport";
import useRefreshBlockNumberID from "../hooks/useRefreshBlockNumber";
import AddressInputPanel from "./AddressInputPanel";
import AdvancedSwapDetailsDropdown from "./AdvancedSwapDetailsDropdown";
import TradePrice from "./TradePrice";
import { ArrowWrapper, Wrapper } from "./styleds";

const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`;

const SwitchIconButton = styled(IconButton)`
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  .icon-up-down {
    display: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    .icon-down {
      display: none;
      fill: white;
    }
    .icon-up-down {
      display: block;
      fill: white;
    }
  }
`;

interface SwapForm {
  isChartExpanded: boolean;
  isChartDisplayed: boolean;
  setIsChartDisplayed: Dispatch<SetStateAction<boolean>>;
}

export default function SwapForm({
  setIsChartDisplayed,
  isChartDisplayed,
  isAccessTokenSupported,
}) {
  const { t } = useTranslation();
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID();
  const warningSwapHandler = useWarningImport();

  const { account, chainId } = useActiveWeb3React();

  // for expert mode
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();
  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency]
  );

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient
  );

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage,
    chainId
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput);

      warningSwapHandler(currencyInput);

      replaceBrowserHistory("inputCurrency", currencyId(currencyInput));
    },
    [onCurrencySelection, warningSwapHandler]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput);
      warningSwapHandler(currencyOutput);

      replaceBrowserHistory("outputCurrency", currencyId(currencyOutput));
    },

    [onCurrencySelection, warningSwapHandler]
  );

  const handlePercentInput = useCallback(
    (percent) => {
      if (maxAmountInput) {
        onUserInput(
          Field.INPUT,
          maxAmountInput.multiply(new Percent(percent, 100)).toExact()
        );
      }
    },
    [maxAmountInput, onUserInput]
  );

  const swapIsUnsupported = useIsTransactionUnsupported(
    currencies?.INPUT,
    currencies?.OUTPUT
  );

  const hasAmount = Boolean(parsedAmount);

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber();
    }
  }, [hasAmount, refreshBlockNumber]);

  return (
    <>
      <>
        <CurrencyInputHeader
          title={t("Swap")}
          subtitle={t("Swap your tokens in SeahorseSwap")}
          setIsChartDisplayed={setIsChartDisplayed}
          isChartDisplayed={false}
          hasAmount={hasAmount}
          onRefreshPrice={onRefreshPrice}
        />
        <div className="nes-container  is-rounded is-dark">
          <Wrapper id="swap-page" style={{ minHeight: "412px" }}>
            <AutoColumn gap="sm">
              <CurrencyInputPanel
                label={
                  independentField === Field.OUTPUT && !showWrap && trade
                    ? t("From (estimated)")
                    : t("From")
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={!atMaxAmountInput}
                showQuickInputButton
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onPercentInput={handlePercentInput}
                onMax={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                id="swap-currency-input"
                showCommonBases
                commonBasesType={CommonBasesType.SWAP_LIMITORDER}
              />

              <AutoColumn justify="space-between">
                <AutoRow
                  justify={isExpertMode ? "space-between" : "center"}
                  style={{ padding: "0 1rem" }}
                >
                  <SwitchIconButton
                    variant="light"
                    scale="sm"
                    onClick={() => {
                      setApprovalSubmitted(false); // reset 2 step UI for approvals
                      onSwitchTokens();
                    }}
                  >
                    <ArrowDownIcon
                      className="icon-down"
                      color={
                        currencies[Field.INPUT] && currencies[Field.OUTPUT]
                          ? "primary"
                          : "text"
                      }
                    />
                    <ArrowUpDownIcon
                      className="icon-up-down"
                      color={
                        currencies[Field.INPUT] && currencies[Field.OUTPUT]
                          ? "primary"
                          : "text"
                      }
                    />
                  </SwitchIconButton>
                  {recipient === null && !showWrap && isExpertMode ? (
                    <Button
                      variant="text"
                      id="add-recipient-button"
                      onClick={() => onChangeRecipient("")}
                    >
                      {t("+ Add a send (optional)")}
                    </Button>
                  ) : null}
                </AutoRow>
              </AutoColumn>
              <CurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                label={
                  independentField === Field.INPUT && !showWrap && trade
                    ? t("To (estimated)")
                    : t("To")
                }
                showMaxButton={false}
                currency={currencies[Field.OUTPUT]}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                id="swap-currency-output"
                showCommonBases
                commonBasesType={CommonBasesType.SWAP_LIMITORDER}
              />

              <Box
                style={{ display: isAccessTokenSupported ? "block" : "none" }}
              >
                <AccessRisk
                  inputCurrency={currencies[Field.INPUT]}
                  outputCurrency={currencies[Field.OUTPUT]}
                />
              </Box>

              {isExpertMode && recipient !== null && !showWrap ? (
                <>
                  <AutoRow
                    justify="space-between"
                    style={{ padding: "0 1rem" }}
                  >
                    <ArrowWrapper clickable={false}>
                      <ArrowDownIcon width="16px" />
                    </ArrowWrapper>
                    <Button
                      variant="text"
                      id="remove-recipient-button"
                      onClick={() => onChangeRecipient(null)}
                    >
                      {t("- Remove send")}
                    </Button>
                  </AutoRow>
                  <AddressInputPanel
                    id="recipient"
                    value={recipient}
                    onChange={onChangeRecipient}
                  />
                </>
              ) : null}

              {showWrap ? null : (
                <AutoColumn gap="7px" style={{ padding: "0 16px" }}>
                  <RowBetween align="center">
                    {Boolean(trade) && (
                      <>
                        <Label>{t("Price")}</Label>
                        {isLoading ? (
                          <Skeleton width="100%" ml="8px" height="24px" />
                        ) : (
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        )}
                      </>
                    )}
                  </RowBetween>
                  <RowBetween align="center">
                    <Label>{t("Slippage Tolerance")}</Label>
                    <Text bold color="primary">
                      {allowedSlippage / 100}%
                    </Text>
                  </RowBetween>
                </AutoColumn>
              )}
            </AutoColumn>
            <Box mt="0.25rem">
              <SwapCommitButton
                swapIsUnsupported={swapIsUnsupported}
                account={account}
                showWrap={showWrap}
                wrapInputError={wrapInputError}
                onWrap={onWrap}
                wrapType={wrapType}
                parsedIndepentFieldAmount={parsedAmounts[independentField]}
                approval={approval}
                approveCallback={approveCallback}
                approvalSubmitted={approvalSubmitted}
                currencies={currencies}
                isExpertMode={isExpertMode}
                trade={trade}
                swapInputError={swapInputError}
                currencyBalances={currencyBalances}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onUserInput={onUserInput}
              />
            </Box>
          </Wrapper>
          {!swapIsUnsupported ? (
            trade && <AdvancedSwapDetailsDropdown trade={trade} />
          ) : (
            <UnsupportedCurrencyFooter
              currencies={[currencies.INPUT, currencies.OUTPUT]}
            />
          )}
        </div>
      </>
    </>
  );
}
