import styled from "styled-components";
import {
  ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  ChartDisableIcon,
} from "@pancakeswap/uikit";
import TransactionsModal from "components/App/Transactions/TransactionsModal";
import GlobalSettings from "components/Menu/GlobalSettings";
import { useExpertModeManager } from "state/user/hooks";
import RefreshIcon from "components/Svg/RefreshIcon";
import { ReactElement, useCallback } from "react";
import { SettingsMode } from "../../../components/Menu/GlobalSettings/types";
import React from "react";

interface Props {
  title: string | ReactElement;
  subtitle: string;
  noConfig?: boolean;
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>;
  isChartDisplayed?: boolean;
  hasAmount: boolean;
  onRefreshPrice: () => void;
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
`;

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager();
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed);
  };
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />);
  const handleOnClick = useCallback(() => onRefreshPrice?.(), [onRefreshPrice]);

  return (
    <CurrencyInputContainer>
      {" "}
      <Flex alignItems="center">
        <Heading as="h1" textAlign={"center"} mb={3}>
          {subtitle}
        </Heading>
      </Flex>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-center" width="100%">
          {" "}
          <div
            className="nes-container  is-rounded is-dark is-dark"
            style={{ background: "var(--colors-cardBorder)" }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              {" "}
              <Heading as="h1" textAlign={"center"} mr={1}>
                {title}
              </Heading>{" "}
              <NotificationDot show={expertMode}>
                <GlobalSettings
                  color="textSubtle"
                  mr="0"
                  mode={SettingsMode.SWAP_LIQUIDITY}
                />
              </NotificationDot>
              <IconButton
                onClick={onPresentTransactionsModal}
                variant="text"
                scale="sm"
              >
                <HistoryIcon color="textSubtle" width="24px" />
              </IconButton>
              <IconButton variant="text" scale="sm" onClick={handleOnClick}>
                <RefreshIcon
                  disabled={!hasAmount}
                  color="textSubtle"
                  width="27px"
                />
              </IconButton>
            </Flex>
          </div>
        </Flex>
      </Flex>{" "}
    </CurrencyInputContainer>
  );
};

export default CurrencyInputHeader;
